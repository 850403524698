















































import { computed, defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import GFlexible from '@/components/g/Flexible.vue'
import UIDropdown from '@/components/ui/Dropdown.vue'
import PagesDashboard from '@/views/pages/Dashboard.vue'
import PagesMap from '@/views/pages/Map.vue'
import { isMoreThanOrEqual, onResize } from '@/composables/mq'

const subpages = {
  dashboard: PagesDashboard,
  map: PagesMap,
  page: GFlexible,
}

export default defineComponent({
  name: 'PageTabs',
  components: { UIDropdown },
  setup(_props, { root }) {
    const { content, template } = useGetters(['content', 'template'])
    const subpage = computed(() => subpages[template.value])
    const dropdownOptions = computed(() =>
      content.value.siblings.map(s => ({ label: s.label, value: s.url }))
    )

    const onTab = (url: string) => {
      root.$router.push({ path: url })
    }

    const isReady = ref(false)

    onMounted(() => {
      onResize(
        window.innerWidth,
        window.innerHeight,
        window.innerWidth / window.innerHeight
      )
      isReady.value = true
    })

    return {
      isMoreThanOrEqual,
      content,
      subpage,
      template,
      dropdownOptions,
      onTab,
      isReady,
    }
  },
})
