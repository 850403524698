













import { defineComponent, ref, onMounted, PropType } from '@vue/composition-api'

import Cta from '@/components/Cta.vue'
import { MapContent } from '@/inc/types'

export default defineComponent({
  name: 'PagesMap',
  components: { Cta },
  props: {
    content: {
      type: Object as PropType<MapContent>,
      required: true,
    },
  },

  setup() {
    const outer = ref<HTMLElement>()
    onMounted(() => {
      // NOTE: managed with CSS
      // setTimeout(() => {
      //   if (!outer.value) {
      //     return
      //   }
      //   // "Fill" the viewport
      //   const top =
      //     outer.value.getBoundingClientRect().top +
      //     document.documentElement.scrollTop
      //   const height = window.innerHeight - top
      //   outer.value.style.height = `${height}px`
      // }, 0)
    })

    return { outer }
  },
})
