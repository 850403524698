











































































// eslint-disable-next-line lines-around-comment
/**
 * Resources:
 *
 * - https://pattern-library.dequelabs.com/components/selects
 * - https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html
 * - https://www.webaxe.org/accessible-custom-select-dropdowns/
 */

/**
 * Slots:
 *
 * trigger: isExpanded, label
 * option: label
 */

/**
 * CSS:
 *
 * .dropdown
 * .label
 * .select
 * .trigger: [aria-expanded]
 * .list
 * .option: .isActive, .isSelected
 */
import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
  onMounted,
  PropType,
} from '@vue/composition-api'

interface Option {
  label: string
  value: string
}

type CustomStyle = Record<string, string>

export default defineComponent({
  name: 'UiDropdown',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    // REVIEW: may be some visually-hidden ?
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    classes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    css: {
      // $style starts as Object but becomes Module on HRM
      // type: Object as PropType<CustomStyle>,
      default: () => ({}),
    },
  },

  setup(props, { emit, slots }) {
    // Checks
    const hasCustomTrigger = slots.trigger !== undefined
    const hasCustomOption = slots.option !== undefined
    let hasInit = false
    let canToggle = false

    const triggerEl = ref<HTMLElement>()
    let blurTimeout: ReturnType<typeof setTimeout>
    let searchTimeout: ReturnType<typeof setTimeout>

    // Statuses
    const isExpanded = ref(false)
    const hasSelection = ref(props.value !== undefined)

    // Values
    const selectedIndex = ref(
      props.value ? props.options.findIndex(o => props.value === o.value) : 0
    )
    const activeIndex = ref<number>(selectedIndex.value)
    const selected = computed(() => props.options[selectedIndex.value])
    const active = computed(() => props.options[activeIndex.value])
    const triggerLabel = computed(() =>
      hasSelection.value ? selected.value.label : props.placeholder
    )

    // Search needle
    let needle = ''

    // Custom styles
    // Module CSS classes are merged with instance module CSS classes
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { $style } = getCurrentInstance()!
    const $customStyle = computed(() =>
      Object.keys($style).reduce((acc: CustomStyle, prop: string) => {
        const base: string = $style[prop]
        const extra = Object.keys(props.css).find(name => name === prop)

        acc[prop] = extra ? `${props.css[extra]} ${base}` : base

        return acc
      }, {})
    )

    const open = () => {
      activeIndex.value = selectedIndex.value
      isExpanded.value = true
      emit('open')
    }

    const close = () => {
      isExpanded.value = false
      emit('close')
    }

    const toggle = () => {
      if (isExpanded.value) {
        // Do not close on focus triggered by first button click
        canToggle && close()
      } else {
        open()
      }
    }

    const init = () => {
      !hasInit && document.addEventListener('keydown', onKeydown)
      hasInit = true
      open()
      canToggle = false
      setTimeout(() => {
        canToggle = true
      }, 250)
    }

    const destroy = () => {
      document.removeEventListener('keydown', onKeydown)
      hasInit = false
      close()
      triggerEl.value!.blur()
    }

    const select = () => {
      selectedIndex.value = activeIndex.value
      hasSelection.value = true
      triggerEl.value!.focus()
      emit('input', selected.value.value)
    }

    const search = (input: string) => {
      const cleanString = (str: string) =>
        str
          .toLowerCase()
          .normalize('NFD')
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
      // Normalize input
      needle += cleanString(input)

      const result = props.options.findIndex(o =>
        cleanString(o.label).startsWith(needle)
      )

      if (result > -1) {
        activeIndex.value = result
      }

      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        needle = ''
      }, 300)
    }

    const onKeydown = (event: KeyboardEvent) => {
      if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return
      }

      // Prevent page scroll
      event.preventDefault()

      switch (event.key) {
        // Move selection
        case 'ArrowUp':
          !isExpanded.value && open()
          activeIndex.value = Math.max(activeIndex.value - 1, 0)
          break
        case 'ArrowDown':
          !isExpanded.value && open()
          activeIndex.value = Math.min(
            activeIndex.value + 1,
            props.options.length - 1
          )
          break
        case 'Home':
          !isExpanded.value && open()
          activeIndex.value = 0
          break
        case 'End':
          !isExpanded.value && open()
          activeIndex.value = props.options.length - 1
          break
        // Close and leave
        case 'Tab':
          destroy()
          break
        // Close and stay
        case 'Escape':
          close()
          break
        // Select and toggle
        case 'Enter':
        case ' ':
          isExpanded.value && select()
          toggle()
          break
        // Search
        default:
          // Quick filter (to avoid f5, Ctrl, …)…
          if (event.key.length === 1) {
            !isExpanded.value && open()
            search(event.key)
          }
      }
    }

    const onSelect = (index: number) => {
      // Prevent blur callback
      clearTimeout(blurTimeout)
      activeIndex.value = index
      select()
      close()
    }

    const onClick = () => {
      toggle()
    }

    onMounted(() => {
      triggerEl.value!.addEventListener('focus', init)
      triggerEl.value!.addEventListener('blur', () => {
        // This timeout allows blur+click combo aka option click
        blurTimeout = setTimeout(() => {
          isExpanded.value && destroy()
        }, 150)
      })
    })

    return {
      hasCustomTrigger,
      hasCustomOption,
      triggerEl,
      triggerLabel,
      isExpanded,
      selected,
      active,
      $customStyle,
      onSelect,
      onClick,
    }
  },
})
