import { render, staticRenderFns } from "./PageTabs.vue?vue&type=template&id=c9530832&scoped=true&"
import script from "./PageTabs.vue?vue&type=script&lang=ts&"
export * from "./PageTabs.vue?vue&type=script&lang=ts&"
import style0 from "./PageTabs.vue?vue&type=style&index=0&id=c9530832&lang=scss&scoped=true&"
import style1 from "./PageTabs.vue?vue&type=style&index=1&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c9530832",
  null
  
)

export default component.exports