var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$customStyle.dropdown},[(_vm.label)?_c('div',{staticClass:"label",class:[_vm.$customStyle.label],attrs:{"id":_vm.id,"aria-hidden":!_vm.showLabel}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"select",class:_vm.$customStyle.select},[_c('button',{ref:"triggerEl",staticClass:"trigger",class:[
        _vm.$customStyle.trigger,
        ( _obj = {}, _obj[_vm.$customStyle.defaultTrigger] = !_vm.hasCustomTrigger, _obj ) ],attrs:{"aria-controls":(_vm.id + "-listbox"),"aria-expanded":_vm.isExpanded,"aria-haspopup":"listbox","aria-labelledby":_vm.id},on:{"click":_vm.onClick}},[_vm._t("trigger",function(){return [_c('span',[_vm._v(_vm._s(_vm.triggerLabel))]),_c('svg',{attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0,0l12,0l-6,8Z"}})])]},{"label":_vm.triggerLabel,"isExpanded":_vm.isExpanded})],2),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],staticClass:"list",class:_vm.$customStyle.list,attrs:{"role":"listbox","tabindex":"-1","id":(_vm.id + "-listbox"),"aria-labelledby":_vm.id,"aria-activedescendant":_vm.active.value}},_vm._l((_vm.options),function(option,i){
      var _obj;
return _c('li',{key:(_vm.id + "-" + i + "-option"),staticClass:"option",class:[
          _vm.$customStyle.option,
          ( _obj = {}, _obj[_vm.$customStyle.defaultOption] = !_vm.hasCustomOption, _obj[_vm.$customStyle.isActive] = option.value === _vm.active.value, _obj[_vm.$customStyle.isSelected] = option.value === _vm.selected.value, _obj['is-active'] =  option.value === _vm.active.value, _obj['is-selected'] =  option.value === _vm.selected.value, _obj ) ],attrs:{"id":option.value,"role":"option","aria-selected":option.value === _vm.active.value},on:{"mousedown":function($event){return _vm.onSelect(i)}}},[_vm._t("option",function(){return [_vm._v(" "+_vm._s(option.label)+" ")]},{"label":option.label})],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }